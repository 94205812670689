body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

.container-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.centered-image {
  width: 100%;
  height: auto;
  max-width: 600px; /* Adjust the max-width as needed */
  margin-bottom: 20px; /* Optional: Add some space below the image */
}

.content {
  padding-top: 5%; /* Adjust based on navbar height */
  padding: 20px;
}

.disclaimer {
  font-size: 0.8em; /* Adjust the font size as needed */
  color: #666; /* Optional: Adjust the text color for better visibility */
  margin-left: 10px; /* Space between the button and the text */
}

.text-container {
  width: 100%;
  max-width: 800px; /* Optional: Adjust the max-width as needed */
  text-align: center; /* Center-align the text */
}

.upload-section {
  margin-bottom: 20px;
}

.results-section {
  margin-top: 20px;
}

.tab a,
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  text-decoration: none;
  color: black;
}

.tab a:hover,
.tab button:hover {
  background-color: #ddd;
}

.tab a.active,
.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
}

.navbar {
  display: flex;
  /* justify-content: space-between; Space between links and image */
  align-items: center; /* Center items vertically */
  padding: 20px 30px; /* Add some padding */
  position: relative;
  background-color: #f8f9fa; /* Optional: background color for navbar */
}

.nav-links {
  display: flex;
  gap: 50px; /* Space between nav links */
  padding-left: 3%;
}

.nav-links a {
  text-decoration: none;
  color: #333; /* Default link color */
  font-weight: bold;
}

.nav-links a.active {
  color: #007bff; /* Color for active link */
}

.image-container {
  display: flex;
  align-items: center; /* Center image vertically */
}

.right-aligned-image {
  width: 150px; /* Desired width */
  height: auto; /* Maintain aspect ratio */
  max-height: 100px; /* Optional: max height to ensure it doesn't get too big */
}

.video-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
